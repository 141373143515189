<template>
    <div class=" move-in-from-top">
        <a :href="sciallink" target="_blank" v-html="socialtext"></a>
    </div>
</template>

<script>
    export default {
        name: "MenuSocial",
        props:["sciallink", "socialtext", 'numb'],
        mounted() {
            let idcounter = this.numb+5
            function menushow(el){
                el.classList.add('start')
            }
            setTimeout(menushow, idcounter*250, this.$el);
        },

    }
</script>

<style scoped>

</style>