<template>
    <div class="block" id="experience">
        <ArticleTitle :rotateds="true" :shovsnakes="true" :articleid="'experience_n'" :articletitle="'Employment'" id="experience_n"/>
        <div class="colomn" id="experience_content">
            <div class="wraper" v-for="expdata in raw.experience_set" :key="expdata.name">
                <ContentBlock :datac="expdata" :hardskill="false"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import ContentBlock from "@/components/ContentBlock";
    import ArticleTitle from "@/components/ArticleTitle";

    export default {
        name: "ExperiencePreviusWorks",
        components: {
            ArticleTitle,
            ContentBlock,

        },
        mounted() {
            // this.$store.dispatch('GetData')
        },
        computed: mapState(["raw",]),
    }
</script>

<style scoped>
    #experience .colomn {
        flex-direction: column;
    }
</style>