<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="599.47" height="834.3" viewBox="0 0 599.47 834.3">
            <g>
                <g>
                    <g>
                        <g class="st0">
                            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="13.0126"
                                            y1="692.3916" x2="497.3373" y2="-16.5663">
                                <stop offset="0" style="stop-color:#FFFFFF"/>
                                <stop offset="0.1045" style="stop-color:#F9F9F9;stop-opacity:0.8955"/>
                                <stop offset="0.2389" style="stop-color:#EAE9E9;stop-opacity:0.7611"/>
                                <stop offset="0.3897" style="stop-color:#D0CFCF;stop-opacity:0.6103"/>
                                <stop offset="0.5526" style="stop-color:#ABAAAA;stop-opacity:0.4474"/>
                                <stop offset="0.7251" style="stop-color:#7D7A7B;stop-opacity:0.2749"/>
                                <stop offset="0.903" style="stop-color:#454142;stop-opacity:0.097"/>
                                <stop offset="1" style="stop-color:#231F20;stop-opacity:0"/>
                            </linearGradient>
                            <path class="st1" d="M420.93,22.34C244.87-44.73,156.27,56.85,126.24,96.49c-30.03,39.63-30.15,71.53,5.26,105.33
                                    c26.58,25.31-36.71,41.68-52.07,46.51C29.9,263.97-8.85,310.99,1.76,382.5s164.23,100.67,242.79,95.35
                                    c55.98-3.77,71.17,16.19,75.28,27.98c0.77,2.17,1.04,4.49,0.79,6.78c-0.25,2.29-1.02,4.5-2.25,6.45
                                    c-19.42,30.6-56.12,57.01-63.29,62.04c-0.63,0.46-1.17,1.03-1.61,1.67c-1.01,1.44-5.21,4.23-22.21,2.88
                                    c-22.55-1.84-44.47,11.5-44.47,11.5s-25.6,15.82-29.86,20.71c-4.26,4.89-6.7,10.36-11.51,14.01c-4.8,3.65-6.7,8.63-18.9,12.8
                                    c-9.95,3.48-10.15,14.73-9.9,18.7c0.1,1.02-0.17,2.04-0.78,2.88c-10.15,14.27-23.93,25.58-39.9,32.76c0,0-7.65,4.66-6.76,19.24
                                    c0.02,0.6-0.08,1.2-0.29,1.76c-0.86,2.39-2.39,9.89,9.58,16.8c0.94,0.56,1.68,1.4,2.1,2.42c1.7,3.88,6.76,13.6,15.56,16.34
                                    c11.13,3.42,25.63,8.11,61.53-6.85c35.9-14.96,67.43-34.2,84.69-38.02c16.54-3.62,59.35-36.21,62.28-54.65
                                    c0.31-2.15,1.43-4.09,3.14-5.44c10.99-8.4,48.33-37.85,60.81-58.07c14.73-23.84,59.58-49.07,51.18-104.43
                                    s-86.9-110.73-139.49-115.65c-50.89-4.75-53.16,12.86-81.24,5.75c-1.78-0.43-3.57-0.86-5.38-1.18
                                    c-9-1.67-39.12-8.89-34.98-30.55c0.96-4.52,3.23-8.67,6.54-11.9c3.31-3.23,7.5-5.42,12.04-6.28
                                    c25.72-5.23,91.59-30.32,96.25-64.66c5.75-42.97-0.86-50.65-31.84-81.6s26.15-76.39,59.86-59.88
                                    c33.71,16.51,73.15-6.21,125.37,24.13s119.96,61.23,170.07,50.91V70.63C513.84,83.66,420.93,22.34,420.93,22.34z M184.84,702.67
                                    c-7.91,4.29-17.78,0-17.78,0s-3.94-16.77,11.51-16.77s12.83,3.28,12.83,3.28c0.34,2.64-0.1,5.33-1.27,7.72
                                    C188.96,699.3,187.12,701.31,184.84,702.67z"/>
                        </g>
                    </g>
                    <path class="cls-1" d="M29.07,834.3c0,0-4.46-9.95-5.76-15.24c-1.31-5.27-1.34-7.34-0.94-12.87c0.38-5.54,2-10.93,3.51-16.12
                            c1.54-5.21,3.37-10.33,5.85-15.33c2.44-5.04,6.04-9.49,10.1-13.24c4.02-3.82,8.36-7.2,12.91-10.08c4.55-2.88,9.21-5.42,13.86-7.93
                            l14.09-7.51l0.98,1.25c-0.83,1.08-1.72,1.82-2.76,2.68c-0.93,0.99-2.2,1.78-3.18,2.72c-1.93,1.92-3.96,3.73-6.04,5.45
                            c-4.16,3.45-8.51,6.57-12.82,9.57c-4.32,3-8.69,5.74-12.73,8.87l-1.51,1.18l-1.45,1.23c-0.99,0.8-1.89,1.68-2.87,2.5
                            c-0.5,0.41-0.93,0.86-1.39,1.3l-1.4,1.31c-0.84,0.94-1.8,1.83-2.61,2.82c-3.37,3.88-7.23,6.98-8.25,12.09
                            c-1.07,5.13-0.14,12.09,0.37,17.17c0.42,4.15,1.12,6.91,1.12,6.91c0.09,0.43,0.24,1.13,0.43,2.18c0.39,2.11,0.35,2.27,0.69,4.13
                            c0.33,1.81,0.51,2.4,0.76,4.05c0.09,0.57,0.16,1.15,0.24,1.77c0,0,0.09,0.76,0.17,1.75c0.09,1.24,0.11,3.79-0.1,7.1
                            c-1.88-7.14-3.12-13.06-3.93-17.35c-0.35-1.83-1.88-10.1-1.82-10.11c0.02,0,0.23,0.75,0.25,1.71c0.02,1.04-0.38,1.4,0.6,9.47
                            C26.1,823.06,28.21,828.99,29.07,834.3z"/>
                    <path class="cls-1" d="M184.84,702.67c-7.91,4.29-17.78,0-17.78,0s-3.94-16.77,11.51-16.77s12.83,3.28,12.83,3.28
                            c0.34,2.64-0.1,5.33-1.27,7.72C188.96,699.3,187.12,701.31,184.84,702.67z"/>
                </g>
            </g>
        </svg>
<!--        <img src="img/snake_finish.svg">-->
    </div>
</template>

<script>
    export default {
        name: "PythonTong"
    }
</script>

<style scoped>
    img {
        width: 50%;
    }

    .st0 {
        opacity: 0.7;
    }

    .st1 {
        fill: url(#SVGID_1_);
    }

    .st2 {
        fill: #C62200;
    }

    .cls-1 {
        animation: blink 2s infinite;
        fill: none;;
    }
</style>