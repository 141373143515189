<template>
    <div class="block" id="developer">
        <div class="intro">
            <h1 id="he"></h1>
            <div id="dev_intro" >
                <p v-html="aboutme"></p>
            </div>
        </div>
        <img id="my_photo" :src="`img/photo.jpg`" alt="My photo" >
    </div>
</template>
<script>


    export default {
        name: "DeveLoperTop",
        props:["profession", "aboutme"],
        methods: {
            typewr() {
                let i = 0;
                let speed = 80; /* The speed/duration of the effect in milliseconds */
                let txt = this.profession
                function typeWriter() {
                    if (i < txt.length) {
                        document.getElementById("he").innerHTML += txt.charAt(i);
                        i++;
                        setTimeout(() => typeWriter(), speed);
                    }
                }
                typeWriter()
            },

        },
        mounted() {
            setTimeout(() => this.typewr(), 1500);
            console.log('HTML ',  this.$props['aboutme'])
            // this.$store.dispatch('GetData')
        }
    }
</script>
<style scoped>

    div#developer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 100px;
        overflow: hidden;
    }

    #developer .intro {
        margin: 0 0 0 120px;
        z-index: 5;
    }

    div#dev_intro {
        font-size: 22px;
        font-weight: 200;
        width: 60%;
        opacity: 0;
        line-height: 2;
        letter-spacing: 1px;
        position: relative;
        animation: fadeIn 1s 3.3s forwards ease-out;
    }
    #my_photo {
        position: absolute;
        right: -100%;
        z-index: 1;
        height: auto;
        animation: move-in-from-right 0.6s 2.5s forwards ease-out;
    }
    @media screen and (max-width:1024px) {
        #developer .intro {
            margin: 0 10px 50px 10px;
        }
        div#dev_intro{
            width: 100%;
            font-size: 20px;
            line-height: 1.3;
            letter-spacing: 0;
            background-color: rgba(0,0,0,0.9);
            font-weight: 100;
            animation: fadeIn 1s 2.3s forwards ease-out;
            opacity: 0.5;
        }
        #my_photo{
            width: 70vw;
            height: 110vw;
            margin-top: 5vh;
            right: 0;
            opacity: 0;
            animation: fadeIn 1.5s 1.5s forwards ease-out;
        }
    }
</style>
