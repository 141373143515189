<template>
    <div class="menu_conteiner">
        <div id="logo" class="move-in-from-top">
            <a href="?developer" class="linkscroll" v-on:click="backtotop">
                <img src="logo.png">
            </a>
        </div>
        <div class="flexy" id="btn">
            <MenuBtn v-for="links, idxs in navLinks" :key="idxs.link" :numb="idxs" :somtext="links.textlink" :somlink="links.link" />
        </div>
        <div class="flexy" id="socials">
            <MenuSocial v-for="j, idxs in socicons" :key="j.link" :numb="idxs" :sciallink="j.link" :socialtext="j.textlink"/>
        </div>
    </div>

</template>

<script>
    import {mapState} from 'vuex'
    import MenuBtn from "@/components/MenuBtn";
    import MenuSocial from "@/components/MenuSocial";

    export default {
        name: 'TopMenu',
        mounted() {
            // this.$store.dispatch('GetData')
            let idcounter = this.numb+2
            function menushow(el){
                el.classList.add('start')
            }
            setTimeout(menushow, idcounter*250, this.$el.childNodes[0]);
            document.addEventListener('scroll',function () {
                if(window.top.scrollY >1){
                    document.getElementsByClassName('menu_conteiner')[0].classList.add('menu_fixed')
                }else{
                    document.getElementsByClassName('menu_conteiner')[0].classList.remove('menu_fixed')
                }
            })
        },
        computed:
            mapState(["raw", "navLinks", "socicons"]),
        components: {
            MenuBtn: MenuBtn,
            MenuSocial: MenuSocial,
        },
        methods: {
            backtotop(elm) {
                elm.preventDefault()
                window.scrollTo({top: 0, behavior: 'smooth'})
            },
        },

    }

</script>

<style>
    .menu_conteiner {
        padding: 0px 120px 37px 120px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: calc(100% - 240px);
        z-index: 99;
        position: fixed;
        top: -100px;
        height: 170px;
        /*animation: move-in-from-top 1s forwards ease-out;*/
    }

    .menu_fixed {

        background-color: black;
        border-bottom: 1px solid #555;
        animation: fixed_menu 0.5s ease-out forwards;
    }

    #logo {
        padding-left: 0.4vw;
    }

    .flexy {
        display: flex;
    }

    .btns {
        /* flex:1; */
        text-align: center;
        font-style: normal;
        font-weight: 200;
        font-size: 22px;
        letter-spacing: 0.04em;
        margin-left: 2vw;
        margin-right: 2vw;
        padding-bottom: 3px;
    }

    .btns.activete {
        border-bottom: 1px solid;
    }

    .btns a {
        /* color: #fff; */
        text-decoration: none;

        background: linear-gradient(to right, #BC0618,  #BC0618 50%, #fff 50%);
        transition: background-position 275ms ease;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 100%;
        background-position: 100%;
    }

    .btns a:hover {
        color: #BC0618;
        background-position: 0 100%;
    }

    #btn {
        margin-left: auto;
    }

    #socials a {
        margin-left: 5px;
        margin-right: 5px;
    }

    #socials svg {
        width: 26px;
        height: 26px;
    }

    #socials svg:hover path, #socials svg:hover ellipse {
        fill: #BC0618;

    }
    #logo img{
        width: 207px;
        height: 21px;
    }
    @keyframes fixed_menu {
        0% {
            top: -100px;
        }
        100% {
            top: -125px;
            padding-bottom: 10px;
        }
    }

    @media screen and (max-width: 1024px) {
        .menu_conteiner {
            padding: 0 20px 0 10px;
            justify-content: space-evenly;
            width: calc(100% - 20px);
        }

        #logo {
            transform: scale(0.6);
            margin-left: -52px;
            width: 100%;
        }

        #btn {
            display: none;
        }
    }

</style>