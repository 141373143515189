<template>
    <div>
        <transition>
            <div id="spiner" v-if="spinner">
                <div>
                    <i class="fa fa-spinner fa-2x fa-spin"></i>
                </div>
            </div>
        </transition>
    </div>

</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "MainSpiner",
        computed:mapState(['spinner', ])
        // mounted() {
        //     document.getElementById('spiner').classList.add('hidespiner')
        // }
    }
</script>

<style scoped>
    div#spiner {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 299;
    }

    .hidespiner {
        animation: fadeOut 1s forwards;
    }

    @keyframes fadeOut {
        0% {
            opacity: 100;
            display: flex
        }
        99% {
            opacity: 0;
        }
        100% {
            display: none !important;
        }
    }

</style>