<template>
    <div class="header_container">
        <div class="header_left" :style="shovsnakes ? '':'width:100%;' ">
            <triline v-if="shovsnakes"/>
            <div class="header_two" :id="articleid" :class="{rotate:rotateds}">
                <span class="over_header">{{articletitle}}</span>
                <span class="header">{{articletitle}}</span>
                <span class="under_header">{{articletitle}}</span>
            </div>
        </div>
        <div v-if="shovsnakes">
            <img class="abs_right"  src="img/python.png">
        </div>
    </div>


</template>

<script>
    import TrilineBlock from "@/components/TrilineBlock";

    export default {
        name: "ArticleTitle",
        props:['articletitle', 'rotateds', 'shovsnakes', 'articleid'],
        components:{
            triline:TrilineBlock,
        }
    }
</script>

<style scoped>
.header_two {
    height: 10vw;
    display: flex;
    flex-direction: column;
    width: fit-content;
    /*margin: 4vw 0;*/
    margin-top: 4vw;
    margin-bottom: 4vw;
    /*padding-left: 280px;*/
}
#hard_skill .header_two{
    margin: 4vw auto 0 auto;
    width: fit-content;
}
.over_header, .under_header, .header{
    /* position: absolute; */
    font-size: 4vw;
    line-height: 3vw;
    width: fit-content;
    text-transform: uppercase;
    font-weight: 400;
    inline-size: max-content;
    /* margin-top: 4vw; */

}
.over_header{
    position: absolute;
    opacity: 0.1;
    margin-top: 0vw;
}
.over_header.start{
    animation: run-top 0.5s 0.5s ease-out forwards;
}



.under_header{
    position: absolute;
    opacity: 0.6;
    margin-top: 0vw;
}
.under_header.start{
    animation: run-down 0.5s 0.8s linear forwards;
}
@keyframes run-top {
        0% {
            margin-top: 0vw;
        }
        100% {
            margin-top: -3vw;
        }
    }

    @keyframes run-down {
        0% {
            margin-top: 0vw;
        }
        100% {
            margin-top: 3vw;
        }
    }

    @media screen and (max-width:1024px) {
        svg {
            width: 100%;
            height: 90vw;
        }

        .abs_right {
            height: 30vh;
            width: 100%;
        }
        .over_header, .under_header, .header{
            font-size: 10vw;
            line-height: 10vw;
        }
        .rotate{
            margin: 50px 0 0 0;

        }
        .header_left {
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        @keyframes run-top{
            0%{margin-top: 0vw;}
            100%{margin-top: -8vw;}
        }
        @keyframes run-down{
            0%{margin-top: 0vw;}
            100%{margin-top: 8vw;}
        }
    }
</style>