<template>
    <div>
        <div class="name">{{datac.name}} <div  v-if="datac.start_work">{{ datac.start_work }} <span v-if="datac.end_work">- {{ datac.end_work }}</span></div></div>
        <div class="rigth">
            <a :href="datac.link" target="_blank">{{datac.link}}</a>
        </div>
        <div class="content" v-html="datac.description"></div>
        <div class="techline" v-if="datac.skils">
            <span class="caps">Technologies:</span>
            <div class="hskill" v-for="skl in datac.skils" :key="skl">
                {{findskill(skl)}},
                 <!-- {{ skl }} -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContentBlock",
        props:["datac", "hardskill"],
        methods:{
            findskill(ar){
                let result = this.$props.hardskill.map(i => (i.skill.find(x => x.id===ar)))
                return result.find(i=> i!=null).name
            },
        }
    }
</script>

<style >
    .title {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .name div{
        font-size: 16px;
        font-weight: 100;
    }
    .name {
        text-transform: capitalize;
        font-size: 50px;
        min-width: max-content;
        margin: 0 0 0 0;
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        /* border-bottom:1px solid ; */
    }

    .content {
        font-size: 24px;
        font-weight: 200;
    }

    .caps {
        text-transform: uppercase;
        font-weight: 500;
        color: var(--link-color);
    }

    .hskill {
        padding: 0 5px;
        opacity: 0.4;
    }

    .techline {
        display: flex;
        flex-wrap: wrap;
    }

    .rigth {
        margin: 0 0px 0 auto;
        text-align: right;
    }
    @media screen and (max-width:1024px) {
        .name {
            font-size: 27px;
            line-height: 1.3;
            margin: 0 0 10px 0;
            opacity: 0.8;
            flex-direction: column;
        }

        .rigth {
            margin: 0 10px 0 auto;
        }

        .content {
            font-size: 18px;
            margin: 20px 0 10px 0;
            font-weight: 100;
        }
        .content span:last-child{
            opacity: 0.6;
            font-weight: lighter;
        }
    }
</style>