<template>
    <div class="block" id="hard_skill">
        <ArticleTitle :shovsnakes="false" :articleid="'hard_skill'" :articletitle="'Hard Skill'" />
        <div class="colomn" id="hard_skill_content">
            <div class="wraper" v-for="skillctegory  in raw.hardskillcategory_set" :key="skillctegory.name">
                <div class="title">
                    <div class="name">{{skillctegory.name}}</div>
                </div>
                <div class="content">
                    <ul>
                        <li v-for="sk in skillctegory.skill" :key="sk.name">{{sk.name}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import ArticleTitle from "@/components/ArticleTitle";
    export default {
        name: "HardSkill",
        computed: {
            ...mapState(["raw", "navLinks", "hskilfocus", "articles"]),
        },
        components:{
            ArticleTitle,
        },
        mounted() {
            // this.$store.dispatch('GetData')
        },
    }
</script>

<style >
    #hard_skill .wraper {
        /* width: calc(20% - 120px); */
        min-width: 200px;
        flex: 1;
    }

    .title {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .name {
        text-transform: capitalize;
        font-size: 50px;
        min-width: max-content;
        margin: 0 0 0 0;
    }

    .content {
        font-size: 24px;
        font-weight: 200;
    }

    @media screen and (max-width: 1024px) {
        #hard_skill .content{
            opacity: 0.6;
        }
        #hard_skill .header_two {
            height: 20vw;
        }

        #hard_skill .wraper {
            width: 100%;
        }

        #hard_skill .title {
            justify-content: center;
            border-bottom: 1px solid #777;
        }

        #hard_skill ul {
            padding: 0 20px;
            margin: 0;
            text-align: center;
        }

        #hard_skill li {
            display: inline-block;
            text-align: center;
            margin: 0 15px;
        }
    }
</style>