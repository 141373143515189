<template>
    <div class="trieline">
        <div class="element" v-for="idx in 3" :key="idx"><img src="img/squer.png"></div>
<!--        <div class="element"><img src="img/squer.png"></div>-->
<!--        <div class="element"><img src="img/squer.png"></div>-->
    </div>
</template>

<script>
    export default {
        name: "TrilineBlock"
    }
</script>

<style scoped>
    .trieline{
        margin-bottom: 12vw;
    }
    .element{
        background-image:url("/public/img/line.png");
        background-repeat: repeat-x;
        background-position: center;
        background-size: 0.2%;
        width: 100%;
        opacity: 0.5;
        display: flex;
        align-content: center;
    }
    .element img{
        margin-left: -50px;
        transform: scale(0.3);
        animation: slide-allwais 10s infinite;
    }
    .trieline .element img {
        margin: 0 -20px 0 auto;
    }

    .trieline .element {
        width: 85%;
    }

    .trieline .element:nth-child(2) {
        width: 100%;

    }

    .trieline .element:nth-child(2) img {
        animation: slide-allwais 5s infinite;
    }

    .trieline .element:nth-child(3) img {
        animation: slide-allwais 7s infinite;
    }
    @media screen and (max-width: 1024px) {
        .trieline {
            display: none;
        }
    }
</style>